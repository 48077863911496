<template>
  <div>
    <section> <!-- graph selector -->
      <div class="d-flex flex-row align-items-center">
        <h6>Graph:</h6>
        <b-form-select class="ml-2" v-model="selectedGraphType" :options="graphTypeList"/>
      </div>
      <div class="my-2" v-if="selectedGraphType">{{selectedGraphType.description}}</div>


    </section>

    <hr class="mt-1 mb-2">
    <section class="d-flex"> <!-- graph list -->
      <div class="mt-5 ml-4 d-flex justify-content-center"  v-if="dataIsLoading">
        <b-spinner small variant=primary></b-spinner>
        <span class="ml-2">loading data for graph...</span>
      </div>
      <div class="mt-5 ml-4 font-italic" v-if="!dataIsLoading && selectedGraphType.requiresProbes && probePoints.length===0">Select one or more points on the geometry to graph</div>
      <div v-else> <!-- render graphs -->
        <span v-if="dataError" class="text-danger">{{dataError}}</span>
        <div v-else>
          <div v-if="selectedGraphType.requiresProbes && !dataIsLoading" >
            <div class="d-flex flex-row-reverse">
              <b-button v-if="probePoints.length > 0" class="px-2 py-0" size="sm" pill variant="outline-secondary" @click="clearProbePoints"> clear </b-button>
            </div>
            <div v-for="probe in probePoints" :key="probe.label" class="mb-2">
              <b-badge pill variant="primary">{{probe.label}}</b-badge>
              <b-button class="float-right" size=sm variant="link-secondary" @click="removeProbe(probe)"><b-icon-x/></b-button>
              <c3-graph class="mt-2" v-if="probe.error == null" :data="dataAtProbePoint(probe)" :config="graphConfig" :height="graphHeight" :width="graphWidth" />
              <div class="text-danger" v-else>{{probe.error}}</div>
            </div>

          </div>
          <!-- else render graph without probe points -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios  from 'axios';
import pako from 'pako';
import C3Graph from './C3Graph.vue';

export default {
  name: 'GraphPanel',
  components: {
    C3Graph
  },
  data() {
    return {
      selectedGraphType: {},
      dataForSelectedGraph: null,
      dataIsLoading: false,
      dataError: '',
      graphHeight: 250,
      graphWidth: 385,
      graphDetails: new Map()
    };
  },
  props: {
    // expects a list of probe points formatted like:
    // [
    //   {label: '1', point: {x, y, z}},
    //   ...
    // ]
    probePoints: {
      type: Array,
      required: true
    },
    graphAssets: {
      required: true
    }
  },
  created() {
    this.selectedGraphTypeChanged();
  },
  beforeDestroy() {
    this.clearProbePoints();
  },
  computed: {
    graphTypeList() {
      let graph_list = [{ value: null, text: 'Please Select' }];
      for (let asset of this.graphAssets) {
        let graphDetails = {
          id: asset.simulationResult.id,
          type: asset.simulationResult.dataset,
          name: asset.simulationResult.qualifier ? `${asset.simulationResult.dataset} · ${asset.simulationResult.qualifier}` : asset.simulationResult.dataset,
          description: '',
          requiresProbes: false
        };

        if (asset.simulationResult.dataset == 'Wind Pressure Coefficients') {
          graphDetails.description = 'Displays the Min, Max, Mean, and RMS pressure coefficients for each wind direction at a selected point on the building';
          graphDetails.requiresProbes= true;
        }
        
        if (!graph_list.includes(graphDetails.name)) {
          graph_list.push({ value: graphDetails, text: graphDetails.name});
        }
      }

      return graph_list;
    },
    assetForSelectedGraph() {
      return this.graphAssets.find(asset => asset.simulationResult.id === this.selectedGraphType.id);
    },
    graphConfig() {
      if (this.selectedGraphType.type == 'Wind Pressure Coefficients' && !this.dataIsLoading) {

        let wd_list = [];
        for (let key of Object.keys(this.dataForSelectedGraph)) {
          if (key != 'mesh_points') {
            wd_list.push(key);
          }
        }

        return {
          color: '#fff',
          axis: {
            x: {
              type: 'category',
              categories: wd_list,
              tick: {
                count: 8,
                centered: true,
                multiline: false
              }
            },
            y: {
              min: -3.0,
              max: 2.0
            }
          },
          tooltip: {
            show: false
          }
        };
      }

      return {};
    }
  },
  methods: {
    clearProbePoints() {
      this.$emit('clearProbePoints');
      this.$forceUpdate();
    },
    removeProbe(probe) {
      this.$emit('removeProbe', probe);
      this.$forceUpdate();
    },
    dataAtProbePoint(probePoint) {
      if(this.dataForSelectedGraph?.mesh_points?.length > 0) {
        // find closest point to clicked point in points array then get the point data for that point
        let px, py, pz, distance; // point x,y,z + temp distance
        let pIdx = 0; // point index
        let dIdx; // data index
        let minDistance = Number.MAX_SAFE_INTEGER; // minimum distance
        for(let point of this.dataForSelectedGraph.mesh_points) {

          //coordinates from the viewer are y-up, vtp and json files from post processor are z-up so we need to transform the coordinates before probing into the data
          px = -point[0]; //x becomes -x
          py = point[2];  //z becomes y
          pz = point[1];  //y becomes z

          distance = Math.sqrt((px - probePoint.point[0])**2 + (py - probePoint.point[1])**2 + (pz - probePoint.point[2])**2);
          if(distance < minDistance) {
            minDistance = distance;
            dIdx = pIdx;
          }

          pIdx++;
        }

        // only return data points that are less than 1.5m away from clicked point
        if(minDistance < 1.5) {
          if (this.selectedGraphType.type == 'Wind Pressure Coefficients') {
            return this.getFourPointPlotDataAtSelectedLocation(dIdx);
          }
        } else {
          probePoint.error = `No data found within 1.5m of the probed location.  Nearest match is ${Math.round(minDistance * 100)/100}m away`;
          this.$forceUpdate();
        }
      }

      return null;
    },
    getFourPointPlotDataAtSelectedLocation(dataIndex) {
      let min_arr = ['CpMin'];
      let max_arr = ['CpMax'];
      let mean_arr = ['CpMean'];
      let rms_arr = ['CpRMS'];
      for (let key of Object.keys(this.dataForSelectedGraph)) {
        if (key != 'mesh_points') {
          let wd = this.dataForSelectedGraph[key];
          min_arr.push(wd['CpMin_pred'][dataIndex]);
          max_arr.push(wd['CpMax_pred'][dataIndex]);
          mean_arr.push(wd['CpMean_pred'][dataIndex]);
          rms_arr.push(wd['CpRMS_pred'][dataIndex]);
        }
      }

      let data_for_graph = {
        columns: [
          min_arr,
          max_arr,
          mean_arr,
          rms_arr
        ],
        type: 'line'
      };
      return data_for_graph;

    },
    async selectedGraphTypeChanged() {
      if (this.assetForSelectedGraph) {

        if (this.selectedGraphType.type == 'Wind Pressure Coefficients') {
          this.$emit('graphRequiresProbes', this.selectedGraphType.requiresProbes);
          this.$emit('probeTypeChanged', 'geometry.study');
        }

        await this.downloadGraphData();
      }

    },
    async downloadGraphData() {
      this.dataIsLoading = true;
      this.dataError = '';

      let config = {
        responseType: 'arraybuffer',
      };

      const instance = axios.create(config);
      const fileExtension = this.getFileExtension(this.assetForSelectedGraph.simulationResult.asset_file);

      try {
        const response = await instance.get(this.assetForSelectedGraph.simulationResult.asset_file, config);
        const rawData = new Uint8Array(response.data);

        // Decompress the data
        if (fileExtension == 'gz') {
          const decompressedData = pako.inflate(rawData, { to: 'string' });
          this.dataForSelectedGraph = JSON.parse(decompressedData);
        } else {
          this.dataForSelectedGraph = JSON.parse(new TextDecoder().decode(rawData));
        }
        this.dataIsLoading = false;
      } catch (error) {
        this.dataError = 'Error occurred during data retrieval.';
        this.dataIsLoading = false;
      }
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      const lastPart = parts[parts.length - 1].toLowerCase();
      const extension = lastPart.split('?')[0];
      return extension;
    }
  },
  watch: {
    selectedGraphType(newValue) {
      if (newValue) {
        this.selectedGraphTypeChanged();
      } else {
        //graphs turned off
        this.$emit('graphRequiresProbes', false);
        this.clearProbePoints();
      }
    }
  }
};
</script>

<style scoped>


</style>